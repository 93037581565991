import { API_URL, BASE_URL } from 'config.js';
import { post, get, put, upload } from './helpers';

export const import_payments = async (payload) => {
    const response = await post(`${API_URL}/billing/import/payments`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const import_payments_update_bank = async (id, payload) => {
    const response = await put(
        `${API_URL}/billing/import/payments/${id}`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const add_account_code_on_transaction = async (payload) => {
    const response = await post(
        `${API_URL}/billing/import/payments/add-account-code`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const add_notes_imported_payments = async (payload) => {
    const response = await upload(
        `${API_URL}/billing/import/payments/notes`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const get_notes_imported_payments = async (id) => {
    const response = await get(
        `${API_URL}/billing/import/payments/notes/${id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_import_payment = async (id) => {
    const response = await get(`${API_URL}/billing/import/payments/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_import_payment_transactions = async (id) => {
    const response = await get(
        `${API_URL}/billing/transactions?filter={imported_payment_id: ${id}}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const import_credit_allocations = async (payload) => {
    const response = await post(
        `${API_URL}/billing/invoices/allocate/bulk`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const add_notes_import_credit_allocations = async (payload) => {
    const response = await upload(
        `${API_URL}/billing/invoices/allocate/bulk/notes`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const get_notes_import_credit_allocations = async (id) => {
    const response = await get(
        `${API_URL}/billing/invoices/allocate/bulk/notes/${id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_import_credit_allocations = async (id) => {
    const response = await get(
        `${API_URL}/billing/invoices/allocate/bulk/${id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getBillingExport = async (params) => {
    const response = await get(`${API_URL}/billing/invoices?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getCreditNotesExport = async (params) => {
    const response = await get(`${API_URL}/billing/credit-notes?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};