import { API_URL } from 'config.js';
import { get, post, put } from './helpers';

export const getRevisions = async () => {
    const response = await get(`${API_URL}/rates/revisions`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_service_levels = async (revisionid) => {
    const response = await get(`${API_URL}/rates/revisions/${revisionid}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_rate_groups = async (revisionid) => {
    const response = await get(
        `${API_URL}/rates/groups?rate_revision_id=${revisionid}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getOneGrouop = async (groupId) => {
    const response = await get(`${API_URL}/rates/groups/${groupId}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAccountGroupList = async (accountId) => {
    const response = await get(
        `${API_URL}/rates/groups/account-rategroup-list/${accountId}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const export_revision = async (revision_id) => {
    const response = await post(
        `${API_URL}/rates/revisions/export?id=${revision_id}`,
        {}
    );
    return {
        status: response.status,
        shipment_response: { ...(await response.json()) },
    };
};

export const publish_group = async (payload) => {
    const response = await post(
        `${API_URL}/rates/groups/account-publish-rategroup`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};
export const submitNewGroup = async (payload) => {
    const response = await post(
        `${API_URL}/rates/groups/account-rategroup`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const get_rate_services = async (revisionid) => {
    const response = await get(`${API_URL}/rates/services`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const updateRateGroup = async (id, payload) => {
    const response = await put(
        `${API_URL}/rates/groups/update-rate-group/${id}`,
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const cloneRateGroup = async (payload) => {
    const response = await post(
        `${API_URL}/rates/groups/clone-rategroup`,
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const publishRateGroup = async (payload) => {
    const response = await post(
        `${API_URL}/rates/groups/publish-rategroup`,
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const editRateGroup = async (payload) => {
    const response = await post(
        `${API_URL}/rates/groups/edit-rategroup`,
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const publishRevision = async (payload) => {
    const response = await post(`${API_URL}/rates/revisions/publish`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const createRateRevision = async (payload) => {
    const response = await post(`${API_URL}/rates/revisions`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const updateServiceArea = async (payload) => {
    const response = await post(`${API_URL}/service-area`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};
