import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller } from 'react-hook-form';

export default function AutocompleteInput({
    label,
    name,
    control,
    choices,
    multiple = false,
    isLoading,
    ...restOfProps
}) {
    const [open, setOpen] = React.useState(false);
    const loading = open && choices.length === 0;

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { value, ...rests },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => (
                <Autocomplete
                    multiple={multiple}
                    {...rests}
                    value={value}
                    options={choices}
                    autoHighlight
                    getOptionLabel={(option) => option && option.name}
                    renderOption={(optionProps, option) => (
                        <Box component="li" {...optionProps}>
                            {option.name}
                        </Box>
                    )}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    loading={isLoading}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                helperText={
                                    error
                                        ? error?.message
                                            ? error?.message
                                            : error?.id.message // use id key
                                        : null
                                }
                                error={!!error}
                                label={label}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: isLoading ? (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ) : null,
                                }}
                                {...restOfProps}
                            />
                        );
                    }}
                    onChange={(_, data) => rests.onChange(data)}
                />
            )}
        />
    );
}

AutocompleteInput.defaultProps = {
    fullWidth: true,
    variant: 'outlined', // outlined ||  filled ||  standard
    size: 'normal', // normal || small
    margin: 'none', // dense
    color: 'secondary', // warning || secondary || warning
    isLoading: false, // when waiting for the choices to be filled with data
};
