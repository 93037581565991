import { API_URL } from 'config.js';
import { get, upload, post } from './helpers';

export const getZones = async (type) => {
    const response = await get(`${API_URL}/zones?type=${type}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getDrivers = async () => {
    const response = await get(`${API_URL}/drivers`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();

    return data;
};

export const getBranches = async () => {
    const response = await get(`${API_URL}/branches`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();

    return data;
};

export const getServiceTags = async (type) => {
    const response = await get(`${API_URL}/tags?type=${type}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getUserDrivers = async () => {
    const response = await get(
        `${API_URL}/users?filter={"role_id":[3]}&sort=["time_created","DESC"]&is_map=true`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getZoneTags = async (type) => {
    const response = await get(`${API_URL}/tags?type=${type}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_revision_tags = async (types = []) => {
    let map_types = [];
    if (Array.isArray(types)) {
        map_types = await types.map((value) => value).join(`,`);
    }

    const response = await get(`${API_URL}/tags?mtype=${map_types}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_revision_zones = async (types) => {
    let map_types = [];
    if (Array.isArray(types)) {
        map_types = await types.map((value) => value).join(`,`);
    }

    const response = await get(`${API_URL}/zones?mtype=${map_types}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const upload_kml = async (payload) => {
    const response = await upload(`${API_URL}/zones/upload_kml`, payload);

    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const upload_json_zone = async (payload) => {
    const response = await post(`${API_URL}/zones/upload_json`, payload);

    return {
        status: response.status,
        ...(await response.json()),
    };
};
