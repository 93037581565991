import { Box, styled } from '@mui/material';

const FlexBox = styled(Box)(() => ({ display: 'flex' }));

const FlexBetween = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const FlexAlignCenter = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const FlexJustifyCenter = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const FlexAlignEnd = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
}));

const FlexAlignStart = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
}));

const FlexAround = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
}));

export {
    FlexBox,
    FlexAlignStart,
    FlexBetween,
    FlexAlignCenter,
    FlexJustifyCenter,
    FlexAlignEnd,
    FlexAround,
};
