import React from 'react';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const Reset = Loadable(lazy(() => import('./reset/Reset')));
const ForgotPass = Loadable(lazy(() => import('./forgot-pass/ForgotPass')));
const Register = Loadable(lazy(() => import('./register/Register')));
const Guides = Loadable(lazy(() => import('./guides/Guides')));
const ShipmentTracker = Loadable(
    lazy(() => import('./tracker/ShipmentTracker'))
);
const Quote = Loadable(lazy(() => import('./quick-quote/Quote')));
const QuoteResponse = Loadable(
    lazy(() => import('./quick-quote/QuoteResponse'))
);

const frontRoutes = [
    {
        path: '/verification',
        element: <Reset />,
    },
    {
        path: '/quote',
        element: <Quote />,
    },
    {
        path: '/quote-response',
        element: <QuoteResponse />,
    },
    {
        path: '/resetpass',
        element: <ForgotPass />,
    },
    {
        path: '/register',
        element: <Register />,
    },
    {
        path: '/guide',
        element: <Guides />,
    },
    {
        path: '/tracker',
        element: <ShipmentTracker />,
    },
    {
        path: '/page/404',
        element: <NotFound />,
    },
];

export default frontRoutes;
