import { combineReducers } from 'redux';
import authReducer from './authReducers';
import mapsReducer from './mapsReducer';
import lockersReducer from './lockersReducer';
import currentMapSettingsReducer from './currentMapSettingsReducer';
import generalDataReducer from './generalDataReducer';
import EcommerceReducer from 'app/redux/reducers/EcommerceReducer';
import NavigationReducer from 'app/redux/reducers/NavigationReducer';
import NotificationReducer from 'app/redux/reducers/NotificationReducer';
import ScrumBoardReducer from 'app/redux/reducers/ScrumBoardReducer';

const rootReducer = combineReducers({
    lockers_data: lockersReducer,
    auth: authReducer,
    maps: mapsReducer,
    current_map_settings: currentMapSettingsReducer,
    general_data: generalDataReducer,
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
});

export default rootReducer;
