import {
    SET_CURRENT_MAP_SETTINGS,
    SET_USER_DRIVERS,
    SET_ZONE_TAGS,
} from '../types/types';

const initialState = {
    is_edit_zone: false,
    draw_mode_value: null,
    edit_zone_form_index: null,
    open_edit_dilage_message: false,
    user_drivers: [],
    zone_index_finder: {},
    is_create_zone_form: false,
    is_map_loaded: false,
    auto_complete_marker: { visible: false, position: null },
    foreground_cover: false,
    zone_tags: [],
    zone_type: null,
    open_overlap_dialog_message: false,
    is_zone_autil_trail: false,
    copied_polygon: null,
    paste_polygon: false,
    is_snapping: false,
    is_drawn_polygon: false,
    create_tag: false,
    /* this is used for address search through google map [start]  */
    address_field_props: {street_address: null, suburb: null, city: null, postal_code: null, zone: null},
    populate_address_fields: false
    /* this is used for address search through google map [end]  */
};

const currentMapSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_MAP_SETTINGS:
            return { ...state, ...action.payload };
        case SET_USER_DRIVERS:
            return { ...state, user_drivers: action.payload };
        case SET_ZONE_TAGS:
            return { ...state, zone_tags: action.payload };
        default:
            return state;
    }
};

export default currentMapSettingsReducer;
