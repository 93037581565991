import { API_URL } from 'config.js';
import { post } from './helpers';

export const verify = async (payload) => {
    const response = await post(`${API_URL}/auth/login`, payload);
    return {
        statuscode: response.status,
        ...(await response.json()),
    };
};

export const verifyCode = async (payload) => {
    const response = await post(`${API_URL}/auth/mfa/verify`, payload);
    return {
        statuscode: response.status,
        ...(await response.json()),
    };
};