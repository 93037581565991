import { API_URL } from 'config.js';
import { post, get } from './helpers';


export const report_embedded_url = async (payload) => {
    const response = await post(
        `${API_URL}/reports/report-embedded-url`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};


export const get_available_report_id = async () => {
    const response = await get(`${API_URL}/fetch-report-ids`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};
