import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';


export default function MUIDateTimePicker({
    defaultValue,
    label,
    openTo,
    inputFormat,
    views,
    setDate,
    ...rest
}) {
    const [value, setValue] = React.useState(defaultValue);

    const handleChange = (newValue) => {
        if (dayjs(newValue).isValid()) {
            setValue(newValue);
            setDate(new Date(newValue));
        } else {
            setDate(null);
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DateTimePicker
                    label="Set Date&Time"
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}

MUIDateTimePicker.defaultProps = {
    label: '',
    inputFormat: 'MMMM-YYYY',
    openTo: 'month',
    views: ['year', 'month'],
    defaultValue: null,
};