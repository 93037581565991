import { API_URL } from 'config.js';
import { get } from './helpers';

export const getPermissions = async () => {
    const response = await get(`${API_URL}/permissions`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};
