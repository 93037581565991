import { Button, Dialog } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { LoadingButton } from '@mui/lab';

const DialogBox = styled('div')(() => ({
    padding: '32px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
}));

const Title = styled('div')(() => ({
    margin: 0,
    marginBottom: '8px',
    textTransform: 'capitalize',
}));

const Controller = styled('div')(() => ({
    margin: '8px',
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflow: 'hidden',
    borderRadius: '300px',
    transition: 'all 250ms',
    '&.yesBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
    '&.noBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.secondary.main} !important`,
            backgroundColor: `${theme.palette.secondary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    margin: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflow: 'hidden',
    borderRadius: '300px',
    transition: 'all 250ms',
    '&.yesBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
    '&.noBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.secondary.main} !important`,
            backgroundColor: `${theme.palette.secondary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
}));

const ConfirmationDialog = (props) => {
    const {
        open,
        text,
        onYesClick,
        onConfirmDialogClose,
        loading,
        title = 'confirm',
        maxWidth = 'xs',
    } = props;
    return (
        <Dialog
            maxWidth={maxWidth}
            open={open}
            onClose={onConfirmDialogClose}
            {...props}
        >
            <DialogBox>
                <Title>{title}</Title>
                <p>{text}</p>
                <Controller>
                    <StyledLoadingButton
                        type="button"
                        color="primary"
                        loading={loading}
                        variant="outlined"
                        className="yesBtn"
                        onClick={onYesClick}
                    >
                        Yes
                    </StyledLoadingButton>
                    <StyledButton
                        className="noBtn"
                        variant="outlined"
                        color="secondary"
                        onClick={onConfirmDialogClose}
                    >
                        No
                    </StyledButton>
                </Controller>
            </DialogBox>
        </Dialog>
    );
};

ConfirmationDialog.defaultProps = {
    fullWidth: true,
    sx: {
        '.MuiDialog-paper': {
            position: 'absolute',
            top: 0,
        },
    },
};

export default ConfirmationDialog;
