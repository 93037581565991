import { API_URL } from 'config.js';
import { post, get } from './helpers';

export const quote_download = async (payload) => {
    const response = await post(`${API_URL}/quote/get-quote`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const quote_optin_rates = async (payload) => {
    const response = await post(`${API_URL}/quote/opt-in`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const quote_rates = async (payload) => {
    const response = await post(`${API_URL}/quote/rates`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const create_shipments = async (payload) => {
    const response = await post(`${API_URL}/createshipments`, payload);
    return {
        status: response.status,
        shipment_response: { ...(await response.json()) },
    };
};

export const quoteGetShipment = async (id) => {
    const response = await get(`${API_URL}/quote/get-shipment/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};