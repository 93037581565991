import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Form, TextInput, SaveButton, email, required } from 'react-admin';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/system';
import { Button, ConfirmDialog } from 'app/components';
import { email_help_desk } from 'infra/services/api';
import { ClearIcon } from '../icons/icons';
import { H4, Span } from 'app/components/Typography';

const ErrorSpan = styled('span')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: '#FF3D57',
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '4px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px',
}));

const GridPosRelative = styled(Grid)(({ theme }) => ({
    position: 'relative',
}));

const HelpdeskDialog = ({
    open,
    setOpen,
    shipmentPostData,
    setShipmentPostData,
}) => {
    const {
        setError,
        formState: { errors },
    } = useForm();
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (data) => {
        let stopSubmit = false;
        if (!data.name) {
            setError('name', {
                types: {
                    required: 'Name is required.',
                },
            });
            stopSubmit = true;
        }
        if (!data.email && !data.mobile_number) {
            setError('mobile_number', {
                types: {
                    required: 'Mobile number is required.',
                },
            });
            setError('email', {
                types: {
                    required: 'Email is required.',
                },
            });
            stopSubmit = true;
        }

        if (stopSubmit) return;

        const postData = {
            shipment: shipmentPostData,
            contact: {
                name: data.name,
                email: data.email,
                mobile_number: data.mobile_number,
            },
            message: data.message,
        };

        const response = await email_help_desk(postData);

        if (response.status === 200 && response.data.success === true) {
            enqueueSnackbar(response.data.message, { variant: 'success' });
        } else {
            enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setOpen(false);
    };

    return (
        <ConfirmDialog
            // actionConfirmText="Send email"
            // actionaConfirm={() => {}}
            handleClose={() => setOpen(false)}
            open={open}
            title="Contact information for a special trip"
            maxWidth={'sm'}
            message={
                <Form onSubmit={onSubmit}>
                    <Grid container>
                        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <Span>
                                Enter your contact details to arrange a special
                                trip.
                            </Span>
                        </Grid>
                        <GridPosRelative item xs={12}>
                            <TextInput source="name" fullWidth />
                            {errors.name && errors.name.types && (
                                <ErrorSpan>
                                    {errors.name.types.required}
                                </ErrorSpan>
                            )}
                        </GridPosRelative>
                        <Grid item xs={12}>
                            <TextInput
                                source="email"
                                validate={email('Email address is invalid.')}
                                fullWidth
                            />
                        </Grid>
                        <GridPosRelative item xs={12}>
                            <TextInput source="mobile_number" fullWidth />
                            {errors.mobile_number &&
                                errors.mobile_number.types && (
                                    <ErrorSpan>
                                        {errors.mobile_number.types.required}
                                    </ErrorSpan>
                                )}
                        </GridPosRelative>
                        <Grid item xs={12}>
                            <TextInput source="message" multiline fullWidth />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                startIcon={true}
                                icon={<ClearIcon fontSize="30" />}
                                onClick={() => setOpen(false)}
                                color="error"
                                size="small"
                                label={'Cancel'}
                            />
                            <SaveButton label="Send email" />
                        </Grid>
                    </Grid>
                </Form>
            }
        />
    );
};

export default HelpdeskDialog;
