const colors = {
    TCG_BLUE: '#32378b',
    TCG_GREEN: '#07bc4c',
    TCG_WHITE: '#fff',
    TCG_GRAY: '#E3E2E3',
    TCG_BLACK: '#444444',
    TCG_RED: '#d32f2f',
    TCG_YELLOW: '#fd0',
    TCG_ORANGE: 'rgb(237,124,48)',
    TCG_DARK_GRAY: '#9C9C9C',
};

export default colors;
