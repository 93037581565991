/* eslint-disable no-undef */
export const HUB_SCANNRR_URL = process.env.REACT_APP_HUB_SCANNER_URL;
export const REACT_APP_GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const MAPS_URL = process.env.REACT_APP_MAPS_URL;
export const REACT_APP_PAYMENT_API = process.env.REACT_APP_PAYMENT_API;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const W3W_API_KEY = process.env.REACT_APP_WHAT3WORDS_KEY;
export const REACT_APP_WHAT3WORDS_KEY = process.env.REACT_APP_WHAT3WORDS_KEY;
export const REACT_APP_WHAT3WORDS_URL = process.env.REACT_APP_WHAT3WORDS_URL;