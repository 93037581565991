import * as React from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const StyledTextField = styled(TextField)(() => ({
    '& label': { fontSize: '14px !important' },
    '& .MuiOutlinedInput-root': {
        borderRadius: '5px !important',
        backgroundColor: '#FFFFFF !important',
        boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%) !important',
    },
}));

export default function MUIDatePicker({
    defaultValue,
    label,
    openTo,
    inputFormat,
    views,
    setDate,
    ...rest
}) {
    const [value, setValue] = React.useState(defaultValue);
    
    const handleChange = (newValue) => {
        setValue(newValue);
        setDate(new Date(newValue));
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    {...rest}
                    openTo={openTo}
                    label={label}
                    views={views}
                    inputFormat={inputFormat}
                    // value={value}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <StyledTextField
                            size="small"
                            variant="outlined"
                            required={false}
                            {...params}
                        />
                    )}
                />
            </Stack>
        </LocalizationProvider>
    );
}

MUIDatePicker.defaultProps = {
    label: '',
    inputFormat: 'MMMM-YYYY',
    openTo: 'month',
    views: ['year', 'month'],
    defaultValue: null,
};
