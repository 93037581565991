import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller } from 'react-hook-form';

const FormInputRadio = ({
    name,
    control,
    label = '',
    options,
    defaultValue,
}) => {
    const generateRadioOptions = () => {
        return options.map((singleOption, key) => (
            <FormControlLabel
                key={key}
                value={singleOption.value}
                label={singleOption.label}
                control={<Radio />}
            />
        ));
    };

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => (
                    <RadioGroup
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onChange}
                    >
                        {generateRadioOptions()}
                    </RadioGroup>
                )}
            />
        </>
    );
};

export default FormInputRadio;

FormInputRadio.defaultProps = {
    options: [
        {
            label: 'Radio Option 1',
            value: '1',
        },
        {
            label: 'Radio Option 2',
            value: '2',
        },
    ],
};
