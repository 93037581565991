import { API_URL } from 'config.js';
import { get } from './helpers';

export const getOpsManager = async (branch_id) => {
    const response = await get(
        `${API_URL}/ops-dashboard/ops-manager?branch_id=${branch_id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const rollup = async (managerids, branch_id) => {
    const response = await get(
        `${API_URL}/ops-dashboard/rollup?branch_id=${branch_id}&ops_managers=${managerids}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const driverPerformance = async (agent_id, date_filter) => {
    const response = await get(
        `${API_URL}/ops-dashboard/drivers-performance?agent_id=${agent_id}&date=${date_filter}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const driverTrends = async (agent_id, start_date, end_date, include_weekends) => {
    const response = await get(
        `${API_URL}/ops-dashboard/drivers-trends?agent_id=${agent_id}&start_date=${start_date}&end_date=${end_date}&include_weekends=${include_weekends}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};