import { API_URL } from 'config.js';
import { post } from './helpers';

export const reset_pass = async (payload) => {
    const response = await post(`${API_URL}/auth/password/reset`, payload);
    if (response?.status !== 200) {
        return await response.json();
    }
    if (response?.status === 404) {
        return await response.json();
    }
    return true;
};

export const request_code = async (payload) => {
    const response = await post(`${API_URL}/auth/password/email`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const register = async (payload) => {
    const response = await post(`${API_URL}/register`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};
