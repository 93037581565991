import { API_URL } from 'config.js';
import { get, upload, put, post, patch } from './helpers';

export const getWorkspaceList = async (query_strings) => {
    const response = await get(
        `${API_URL}/workspaces?filter={}&range=[0,1000]&sort=["id","ASC"]`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const addWorkspaceApi = async (payload) => {
    const response = await post(`${API_URL}/workspaces`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const editWorkspaceApi = async (id, payload) => {
    const response = await put(`${API_URL}/workspaces/${id}`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const getWorkspaceInfo = async (id) => {
    const response = await get(`${API_URL}/workspaces/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getRolesViewReports = async () => {
    const response = await get(`${API_URL}/reports-view-roles/`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const addWorkspaceReportApi = async (payload) => {
    const response = await post(`${API_URL}/workspace-reports`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const editWorkspaceReportApi = async (id, payload) => {
    const response = await put(`${API_URL}/workspace-reports/${id}`, payload);
    return {
        ok: response?.status,
        ...(await response.json()),
    };
};
