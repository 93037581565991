import {
    SET_CURRENT_MAP_SETTINGS,
    SET_USER_DRIVERS,
    SET_ZONE_TAGS,
} from '../types/types';

export const setCurrentMapSettings = (mapSettings) => ({
    type: SET_CURRENT_MAP_SETTINGS,
    payload: mapSettings,
});

export const setCurrentMapSettingsUserDrivers = (userDrivers) => ({
    type: SET_USER_DRIVERS,
    payload: userDrivers,
});

export const setCurrentMapSettingsZoneTags = (tags) => ({
    type: SET_ZONE_TAGS,
    payload: tags,
});
