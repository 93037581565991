import {
    SET_GENERAL_DATA,
    SET_SALES_AGENTS,
    SET_ENTERPRISE_TYPES,
    SET_RATE_GROUPS,
    SET_SERVICE_DAYS_TAGS,
    SET_SERVICE_DAYS_ZONES,
    SET_SURCHARGES_ZONES,
    SET_SURCHARGES_TAGS,
    SET_SETTINGS,
    SET_RATES_ZONES,
    SET_RATES_TAGS,
} from '../types/types';

const initialState = {
    general_data: null,
    enterprise_types: [],
    sales_agents: [],
    rate_groups: [],
    serice_days_tags: [],
    serice_days_zones: [],
    surcharges_zones: [],
    surcharges_tags: [],
    rates_zones: [],
    rates_tags: [],
    settings: null,
};

const generalDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GENERAL_DATA:
            return { ...state, general_data: action.payload };
        case SET_ENTERPRISE_TYPES:
            return { ...state, enterprise_types: action.payload };
        case SET_SALES_AGENTS:
            return { ...state, sales_agents: action.payload };
        case SET_RATE_GROUPS:
            return { ...state, rate_groups: action.payload };
        case SET_SERVICE_DAYS_ZONES:
            return { ...state, serice_days_zones: action.payload };
        case SET_SERVICE_DAYS_TAGS:
            return { ...state, serice_days_tags: action.payload };
        case SET_SURCHARGES_ZONES:
            return { ...state, surcharges_zones: action.payload };
        case SET_SURCHARGES_TAGS:
            return { ...state, surcharges_tags: action.payload };
        case SET_RATES_ZONES:
            return { ...state, rates_zones: action.payload };
        case SET_RATES_TAGS:
            return { ...state, rates_tags: action.payload };
        case SET_SETTINGS:
            return { ...state, settings: action.payload };
        default:
            return state;
    }
};

export default generalDataReducer;
