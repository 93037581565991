import {
    SET_LOCKERS_DATA,
} from '../types/types';

const initialState = {
    lockers_data: [],
};

const lockersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOCKERS_DATA:
            return { ...state, lockers_data: action.payload };
        default:
            return state;
    }
};

export default lockersReducer;
