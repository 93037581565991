import { Button, Dialog, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const DialogBox = styled('div')(() => ({
    width: 360,
    padding: '32px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
}));

const Title = styled('h4')(() => ({
    margin: 0,
    marginBottom: '8px',
}));

const Controller = styled('div')(() => ({
    margin: '8px',
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflow: 'hidden',
    borderRadius: '300px',
    transition: 'all 250ms',
    '&.yesBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
    '&.noBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.secondary.main} !important`,
            backgroundColor: `${theme.palette.secondary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
}));

const AlertMsgSpinner = ({
    open,
    text,
    onYesClick,
    onConfirmDialogClose,
    title = 'confirm',
}) => {

    const capitalizeFirstLetter = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };

    return (
        <Dialog maxWidth="xs" open={open}>
            <DialogBox>
                <Title>{capitalizeFirstLetter(title)}</Title>
                <Box
                    flex={1}
                    sx={{
                        padding: 5,
                    }}
                >
                    <CircularProgress className="progress" color="secondary" />
                </Box>
                {onConfirmDialogClose && (
                    <Controller>
                        <StyledButton
                            className="noBtn"
                            variant="outlined"
                            color="secondary"
                            onClick={onConfirmDialogClose}
                        >
                            Close
                        </StyledButton>
                    </Controller>
                )}
            </DialogBox>
        </Dialog>
    );
};

export default AlertMsgSpinner;
