import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from './Button';
import { CheckIcon, ClearIcon } from './icons/icons';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmDialog = ({
    showTitle,
    actionaConfirm,
    actionaCancel,
    title,
    children,
    message,
    onClose,
    open,
    handleClose,
    actionConfirmText = 'Confirm',
    ...props
}) => {
    return (
        <Dialog open={open} onClose={actionaCancel || handleClose} {...props}>
            <DialogTitle
                sx={{
                    height: 20,
                    alignItems: 'center',
                }}
            >
                {title}
                <IconButton
                    aria-label="close"
                    onClick={actionaCancel || handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Divider sx={{ mt: 2 }} />

            <DialogContent>
                <div>{message}</div>
            </DialogContent>

            {(actionaConfirm || actionaCancel) && (
                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    {actionaConfirm && (
                        <Button
                            startIcon={true}
                            icon={<ClearIcon fontSize="30" />}
                            onClick={actionaCancel}
                            color="error"
                            size="small"
                            label={'Cancel'}
                        />
                    )}
                    {actionaCancel && (
                        <Button
                            startIcon={true}
                            icon={<CheckIcon fontSize="30" />}
                            onClick={actionaConfirm}
                            color="primary"
                            size="small"
                            autoFocus
                            label={actionConfirmText}
                        />
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

ConfirmDialog.defaultProps = {
    showTitle: true,
    title: 'Modal Title',
    message: 'Please confirm action!',
    actionaConfirm: null,
    actionaCancel: null,
    fullWidth: true,
    handleClose: () => {},
    maxWidth: 'xs', // xs || sm || md || xl ||  ||false
    sx: {
        '.MuiDialog-paper': {
            position: 'absolute',
            top: 0,
        },
    },
};

export default ConfirmDialog;
