import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import localforage from 'localforage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'root',
    whitelist: ['maps', 'general_data', 'session', 'lockers_data'],
    storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configStore = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export default configStore;

export const store = configStore;

export const persistedStore = persistStore(store);
