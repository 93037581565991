// import { red } from '@mui/material/colors';
// import { components } from './components';

// const themeOptions = {
//     typography: {
//         fo
//         fontSize: 14,
//         body1: { fontSize: '14px' },
//     },

//     status: { danger: red[500] },
//     components: { ...components },
// };

// export default themeOptions;

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { red } from '@mui/material/colors';
import { components } from './components';

const themeOptions = createTheme({
  palette: {
     primary: {
        main: "#264796"
               },
     secondary: {
        main: "#ffcc80"
                }
           },
status: { danger: red[500] },
components: { ...components },
           typography: {
            fontFamily: 'Eurostile Regular',
            fontSize:'24px !important'
          },
});

export default themeOptions;

export const lightTheme = {
        palette: {
            mode: "light",
            primary: {
                main: "#264796",
            },
            secondary: {
                light: "#5f5fc4",
                main: "#264796",
                dark: "#001064",
                contrastText: "#fff",
            },
            background: {
                default: "#fcfcfe",
            },
        },
    };
