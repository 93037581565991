import { API_URL } from 'config.js';
import { post, get, put } from './helpers';

export const searchWaybill = async (payload) => {
    const response = await post(`${API_URL}/tools/seachWaybill`, payload);
    if (response?.status !== 200) {
        return await response.json();
    }
    if (response?.status === 404) {
        return await response.json();
    }
    return true;
};

export const get_waybill_parcels = async (track_ref) => {
    const response = await get(`${API_URL}/shipments/tracking-stickers/${track_ref}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const importShipments = async (payload) => {
    const response = await post(`${API_URL}/import-shipments`, payload);
    return {
        status: response.status,
        data: await response.json(),
    };
};

export const updateImportShipments = async (id, payload) => {
    const response = await put(`${API_URL}/import-shipments/${id}`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};