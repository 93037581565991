import { Button, Dialog } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { LoadingButton } from '@mui/lab';

const DialogBox = styled('div')(() => ({
    width: 360,
    padding: '32px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
}));

const Title = styled('h4')(() => ({
    margin: 0,
    marginBottom: '8px',
    textTransform: 'capitalize',
}));

const Controller = styled('div')(() => ({
    margin: '8px',
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflow: 'hidden',
    borderRadius: '300px',
    transition: 'all 250ms',
    '&.yesBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
    '&.noBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.secondary.main} !important`,
            backgroundColor: `${theme.palette.secondary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    margin: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    overflow: 'hidden',
    borderRadius: '300px',
    transition: 'all 250ms',
    '&.yesBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
    '&.noBtn': {
        '&:hover': {
            color: '#ffffff',
            background: `${theme.palette.secondary.main} !important`,
            backgroundColor: `${theme.palette.secondary.main} !important`,
            fallbacks: [{ color: 'white !important' }],
        },
    },
}));

const NotifyDialog = ({
    open,
    text,
    onNotifyClose,
    title = 'Rates has been updated!',
}) => {
    return (
        <Dialog maxWidth="xs" open={open} onClose={onNotifyClose}>
            <DialogBox>
                <Title>{title}</Title>
                <p>{text}</p>
                <Controller>
                    <StyledLoadingButton
                        type="button"
                        color="primary"
                        variant="outlined"
                        className="yesBtn"
                        onClick={onNotifyClose}
                    >
                        Ok
                    </StyledLoadingButton>
                </Controller>
            </DialogBox>
        </Dialog>
    );
};

export default NotifyDialog;
