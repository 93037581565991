import PeopleAlt from '@mui/icons-material/PeopleAlt';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import InboxRounded from '@mui/icons-material/InboxRounded';
import PermDataSetting from '@mui/icons-material/PermDataSetting';
import Report from '@mui/icons-material/Report';
import FiberSmartRecordSharp from '@mui/icons-material/FiberSmartRecordSharp';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Star from '@mui/icons-material/Star';
import PanTool from '@mui/icons-material/PanTool';
import AirplaneTicket from '@mui/icons-material/AirplaneTicket';
import PermDataSettingSharp from '@mui/icons-material/PermDataSettingSharp';
import DeviceHub from '@mui/icons-material/DeviceHub';
import DocumentScanner from '@mui/icons-material/DocumentScanner';
import MapSharp from '@mui/icons-material/MapSharp';
import Collections from '@mui/icons-material/Collections';
import CollectionsBookmarkOutlined from '@mui/icons-material/CollectionsBookmarkOutlined';
import Search from '@mui/icons-material/Search';
import ImportExport from '@mui/icons-material/ImportExport';
import DeliveryDining from '@mui/icons-material/DeliveryDining';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SmsIcon from '@mui/icons-material/Sms';
import Person from '@mui/icons-material/Person';
import Edit from '@mui/icons-material/Edit';
import ComputerRounded from '@mui/icons-material/ComputerRounded';
import Refresh from '@mui/icons-material/Refresh';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ExitToApp from '@mui/icons-material/ExitToApp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import LocalShippingTwoTone from '@mui/icons-material/LocalShippingTwoTone';
import GifBox from '@mui/icons-material/GifBox';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import AddBoxTwoTone from '@mui/icons-material/AddBoxTwoTone';
import GifBoxOutlined from '@mui/icons-material/GifBoxOutlined';
import LocalMallRounded from '@mui/icons-material/LocalMallRounded';
import StarBorder from '@mui/icons-material/StarBorder';
import PlusOne from '@mui/icons-material/PlusOne';
import ThumbUpSharp from '@mui/icons-material/ThumbUpSharp';
import ThumbDownSharp from '@mui/icons-material/ThumbDownSharp';
import Home from '@mui/icons-material/Home';
import Business from '@mui/icons-material/Business';
import LocalPostOffice from '@mui/icons-material/LocalPostOffice';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForward from '@mui/icons-material/ArrowForward';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SourceIcon from '@mui/icons-material/Source';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Money from '@mui/icons-material/Money';
import LocalShippingRounded from '@mui/icons-material/LocalShippingRounded';
import Analytics from '@mui/icons-material/Analytics';
import Dashboard from '@mui/icons-material/Dashboard';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

export {
    RadioButtonChecked,
    Analytics,
    Dashboard,
    LocalShippingRounded,
    Money,
    EventNoteIcon,
    FileUploadIcon,
    TextSnippetIcon,
    MonetizationOnIcon,
    RvHookupIcon,
    LocalShippingIcon,
    GpsNotFixedIcon,
    LanguageIcon,
    LocationOnIcon,
    AssignmentReturnIcon,
    GpsFixedIcon,
    ContentCopyIcon,
    SourceIcon,
    FormatListBulletedIcon,
    CameraAltIcon,
    QrCodeIcon,
    DownloadForOfflineIcon,
    CallMadeIcon,
    SettingsIcon,
    RefreshIcon,
    EditLocationAltIcon,
    NotificationsIcon,
    NotificationsOffIcon,
    NotificationsActiveIcon,
    ArrowForward,
    AirplanemodeActiveIcon,
    Business,
    LocalPostOffice,
    Home,
    ThumbUpSharp,
    ThumbDownSharp,
    StarBorder,
    PlusOne,
    GifBoxOutlined,
    LocalMallRounded,
    AddBoxTwoTone,
    AddBoxOutlined,
    GifBox,
    LocalShippingTwoTone,
    ClearIcon,
    CheckIcon,
    RemoveRedEyeIcon,
    CheckCircleOutlineIcon,
    ExitToApp,
    Refresh,
    ComputerRounded,
    Person,
    Edit,
    SmsIcon,
    VerifiedUserIcon,
    PeopleAlt,
    PowerSettingsNew,
    AdminPanelSettingsOutlined,
    FormatListBulleted,
    InboxRounded,
    PermDataSetting,
    Report,
    FiberSmartRecordSharp,
    ReportGmailerrorred,
    AttachMoney,
    Star,
    AirplaneTicket,
    PanTool,
    PermDataSettingSharp,
    DeviceHub,
    DocumentScanner,
    MapSharp,
    Collections,
    CollectionsBookmarkOutlined,
    Search,
    ImportExport,
    DeliveryDining,
    DepartureBoardIcon,
    InfoIcon,
    ViewQuiltIcon,
};
