import React from 'react';
import { useDispatch } from 'react-redux';
import {
    GoogleMap,
    LoadScript,
    Marker,
    Autocomplete,
} from '@react-google-maps/api';
import { Box, Button, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import { setCurrentMapSettings } from 'core/redux/actions/currentMapSettingActions';

const containerStyle = {
    width: '100%',
    height: '500px',
};

const GOOGLE_LIBRARIES = ['places'];

const GoogleMapComp = () => {
    const dispatch = useDispatch();
    const [autocomplete, setautocomplete] = React.useState(null);
    const [map, setMap] = React.useState(null);
    const [lat, setLat] = React.useState(-28.48322);
    const [lng, setLng] = React.useState(24.676997);
    const [zoom, setZoom] = React.useState(5);
    const [markerPosition, setMarkerPosition] = React.useState({
        lat: lat,
        lng: lng,
    });
    const [markerVisible, setMarkerVisible] = React.useState(false);
    const dialogMapRef = React.useRef();
    const autocompleteRef = React.useRef();

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onPlaceChanged = () => {
        console.log('onPlaceChanged');
        if (autocomplete !== null) {
            const placeInfo = autocomplete.getPlace();
            if (placeInfo.hasOwnProperty('geometry')) {
                console.log(placeInfo);
                const lat = placeInfo.geometry.location.lat();
                const lng = placeInfo.geometry.location.lng();
                setLat(lat);
                setLng(lng);
                setZoom(14);
                setMarkerPosition({ lat: lat, lng: lng });
                setMarkerVisible(true);

                if (placeInfo.hasOwnProperty('address_components')) {
                    const field_props = {};
                    placeInfo.address_components.map((data, index) => {
                        if (data.types.indexOf('street_number') > -1) {
                            // street number
                            field_props.street_address = data.short_name;
                            field_props.street_number = data.short_name;
                        } else if (data.types.indexOf('route') > -1) {
                            // street address
                            if (field_props.hasOwnProperty('street_address')) {
                                field_props.street_address +=
                                    ' ' + data.short_name;
                            } else {
                                field_props.street_address = data.short_name;
                            }
                            field_props.street_name = data.long_name;
                        } else if (
                            data.types.indexOf('sublocality_level_1') > -1 ||
                            data.types.indexOf('sublocality') > -1
                        ) {
                            // suburb
                            field_props.suburb = data.short_name;
                        } else if (data.types.indexOf('locality') > -1) {
                            // city
                            field_props.city = data.short_name;
                        } else if (
                            data.types.indexOf('administrative_area_level_1') >
                            -1
                        ) {
                            // province/zone
                            field_props.zone = data.long_name;
                        } else if (data.types.indexOf('postal_code') > -1) {
                            // postal code
                            field_props.postal_code = data.short_name;
                        } else if (data.types.indexOf('country') > -1) {
                            // country
                            field_props.country = data.long_name;
                        }
                    });

                    if (placeInfo.hasOwnProperty('formatted_address')) {
                        field_props.formatted_address =
                            placeInfo.formatted_address;
                    }

                    field_props.longitude = lng;
                    field_props.latitude = lat;

                    dispatch(
                        setCurrentMapSettings({
                            address_field_props: field_props,
                        })
                    );
                }
            } else {
                const lat = -28.48322;
                const lng = 24.676997;
                setLat(lat);
                setLng(lng);
                setZoom(5);
                setMarkerPosition({ lat: lat, lng: lng });
                setMarkerVisible(false);
            }
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const onSearchClear = () => {
        autocompleteRef.current.value = '';
        const lat = -28.48322;
        const lng = 24.676997;
        setMarkerPosition({ lat: lat, lng: lng });
        setMarkerVisible(false);
    };

    const autocompleteOnLoad = React.useCallback(function callback(
        autocomplete
    ) {
        console.log('autocompleteOnLoad');
        setautocomplete(autocomplete);
    },
    []);

    return (
        <LoadScript
            id="script-loader"
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
            language="en"
            region="za"
            libraries={GOOGLE_LIBRARIES}
            loadingElement={<LoadingElement />}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                version="weekly"
                onLoad={onLoad}
                options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    center: { lat: lat, lng: lng },
                    zoom: zoom,
                }}
                ref={dialogMapRef}
            >
                {map ? (
                    <>
                        <Box
                            sx={{
                                position: 'relative',
                                top: 5,
                                left: 5,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Autocomplete
                                onLoad={autocompleteOnLoad}
                                onPlaceChanged={onPlaceChanged}
                                restrictions={{ country: ['za'] }}
                            >
                                <input
                                    type="text"
                                    placeholder="Search Google Maps"
                                    style={{
                                        boxSizing: `border-box`,
                                        border: `1px solid transparent`,
                                        width: `270px`,
                                        height: `37px`,
                                        padding: `0 12px`,
                                        borderRadius: `3px`,
                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                        fontSize: `14px`,
                                        outline: `none`,
                                        textOverflow: `ellipses`,
                                    }}
                                    ref={autocompleteRef}
                                />
                            </Autocomplete>
                            <Button
                                variant={'contained'}
                                onClick={onSearchClear}
                            >
                                Clear search bar
                            </Button>
                        </Box>

                        <Marker
                            position={markerPosition}
                            visible={markerVisible}
                            icon={{
                                url: '/assets/images/maps/blue-marker.png',
                                scaledSize: new window.google.maps.Size(35, 35),
                            }}
                        />
                    </>
                ) : null}

                {markerVisible && <PopulateButton />}
            </GoogleMap>
        </LoadScript>
    );
};

const PopulateButton = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const onPopulateClick = () => {
        dispatch(
            setCurrentMapSettings({
                populate_address_fields: true,
            })
        );

        const key = enqueueSnackbar(`Successfully populated the fields.`, {
            variant: 'success',
        });

        setTimeout(() => {
            closeSnackbar(key);
        }, 2000);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                top: 5,
                left: 5,
            }}
        >
            <Button variant={'contained'} onClick={onPopulateClick}>
                Populate Fields
            </Button>
        </Box>
    );
};

const LoadingElement = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
            }}
        >
            <Box position="relative">
                <CircularProgress className="circleProgress" />
            </Box>
        </div>
    );
};

export default GoogleMapComp;
