import React from 'react';
import emptyLottie from 'app/assets/lotties/empty-state.json';
import Lottie from 'react-lottie';

const LoadLottie = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: emptyLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div>
            <Lottie options={defaultOptions} height={500} width={500} />
        </div>
    );
};

export default LoadLottie;
