import {
    SET_GENERAL_DATA,
    SET_SALES_AGENTS,
    SET_ENTERPRISE_TYPES,
    SET_RATE_GROUPS,
    SET_SERVICE_DAYS_TAGS,
    SET_SERVICE_DAYS_ZONES,
    SET_SURCHARGES_ZONES,
    SET_SURCHARGES_TAGS,
    SET_SETTINGS,
    SET_RATES_ZONES,
    SET_RATES_TAGS,
} from '../types/types';

export const setGeneralData = (generalDataARrrays) => ({
    type: SET_GENERAL_DATA,
    payload: generalDataARrrays,
});

export const setSalesAgents = (values) => ({
    type: SET_SALES_AGENTS,
    payload: values,
});

export const setEnterpirseTypes = (values) => ({
    type: SET_ENTERPRISE_TYPES,
    payload: values,
});

export const setRateGroups = (values) => ({
    type: SET_RATE_GROUPS,
    payload: values,
});

export const setServiceDaysTags = (values) => ({
    type: SET_SERVICE_DAYS_TAGS,
    payload: values,
});

export const setServiceDaysZones = (values) => ({
    type: SET_SERVICE_DAYS_ZONES,
    payload: values,
});

export const setSurchargesZones = (values) => ({
    type: SET_SURCHARGES_ZONES,
    payload: values,
});

export const setSurchargesTags = (values) => ({
    type: SET_SURCHARGES_TAGS,
    payload: values,
});

export const setRatesZones = (values) => ({
    type: SET_RATES_ZONES,
    payload: values,
});

export const setRatesTags = (values) => ({
    type: SET_RATES_TAGS,
    payload: values,
});

export const setSettings = (values) => ({
    type: SET_SETTINGS,
    payload: values,
});
