/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { countries } from './countryList';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

const StyledAutoComplete = styled(Autocomplete)(() => ({
    minWidth: '188px !important',
    width: '100%',
    marginTop: '5px',
    '& label': { fontSize: '14px' },
    '& label.MuiInputLabel-shrink': { marginTop: '0px' },
    '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
        '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: '8px 0px !important',
            maxHeight: 10,
        },
    },
}));

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
              .toUpperCase()
              .replace(/./g, (char) =>
                  String.fromCodePoint(char.charCodeAt(0) + 127397)
              )
        : isoCode;
}

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function CountrySelect({
    control,
    name,
    label,
    ...restOfProps
}) {
    const classes = useStyles();

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={({
                    field: { value, ...rests },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                }) => (
                    <StyledAutoComplete
                        {...rests}
                        id="country-select-demo"
                        options={countries}
                        sx={{ width: '100%' }}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option && option.label}
                        renderOption={(optionProps, option) => {
                            return (
                                <Box component="li" {...optionProps}>
                                    <span>{countryToFlag(option.code)}</span>
                                    {option.label} ({option.code}) +
                                    {option.phone}
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                                {...restOfProps}
                            />
                        )}
                        onChange={(_, data) => rests.onChange(data)}
                    />
                )}
            />
        </>
    );
}
