import { API_URL } from 'config.js';
import { get } from './helpers';

export const events = async (object_id = '', type = '') => {
    const response = await get(
        `${API_URL}/events?object_id=${object_id}&type=${type}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();

    return data;
};
