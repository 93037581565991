import * as React from 'react';
import { TextInput } from 'react-admin';

const RATextInput = ({ ...props }) => {
    return <TextInput {...props} />;
};

export default RATextInput;

RATextInput.defaultProps = {
    isRequired: false,
    fullWidth: true,
    variant: 'outlined', // outlined ||  filled ||  standard
    size: 'normal', // normal || small
    margin: 'none', // dense
    color: 'secondary', // warning || secondary || warning
};
