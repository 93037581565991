import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, styled } from '@mui/material';

const StyledInput = styled(TextField)(() => ({
    '& label': { fontSize: '14px' },
    '& .MuiOutlinedInput-root': {
        marginBottom: '5px',
        borderRadius: '5px',
    },
}));

const TextInput = ({ label, name, control, ...restTextField }) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => {
                return (
                    <StyledInput
                        helperText={error ? error.message : null}
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        label={label}
                        {...restTextField}
                    />
                );
            }}
        />
    );
};

export default TextInput;

TextInput.defaultProps = {
    fullWidth: true,
    variant: 'outlined', // outlined ||  filled ||  standard
    size: 'normal', // normal || small
    margin: 'dense', // dense || none
    color: 'secondary', // warning || secondary || warning
    label: 'Label',
    name: 'textInput',
};
