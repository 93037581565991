import React from 'react';
import { AuthProvider } from 'app/contexts/LoginAuthContext';
import { SettingsProvider } from 'app/contexts/SettingsContext';
import { MatxTheme } from './components';
import routes from './routes';
import { useRoutes } from 'react-router-dom';
import { store, persistedStore } from 'core/redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {Helmet} from "react-helmet";
import HtmlReactParser from 'html-react-parser';

const account_users = [
    'AccountSuperUser',
    'AccountUser',
    'AccountUserNonBilling'
];

const App = () => {
    const content = useRoutes(routes);
    const [custom_widget, setCustomWidget] = React.useState("");
    const [store_status, setStoreStatus] = React.useState(false);

    React.useEffect((data) => {
        // check if the type of user is admin or client
        if (localStorage.getItem('role') === null || account_users.includes((localStorage.getItem('role')))) {
            if(custom_widget !== store.getState().general_data.settings?.custom_widget_html && typeof store.getState().general_data.settings?.custom_widget_html !== "undefined"){
                setCustomWidget(store.getState().general_data.settings?.custom_widget_html);
            }
        }else{ // removes the existing widget
            const widget_comp = document.querySelector(`[data-product="web_widget"]`);
            if(widget_comp){
                widget_comp.nextSibling.remove();
                widget_comp.remove();
            }
        }
    }, [store_status]);
    store.subscribe(() => {
        setStoreStatus(!store_status);
    });
    return (
        <Provider store={store}>
            <PersistGate persistor={persistedStore} loading={null}>
                <SettingsProvider>
                    <MatxTheme>
                        <Helmet>
                            {HtmlReactParser(`${custom_widget}`)}
                        </Helmet>
                        <AuthProvider>{content}</AuthProvider>
                    </MatxTheme>
                </SettingsProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
