// import { MatxLoading } from "app/components";
import React, { createContext, useEffect, useReducer } from 'react';
import authProvider from 'app/hooks/Auth';
import { useNavigate } from 'react-router-dom';
import { setSettings } from 'core/redux/actions/generalDataActions';
import { getSettings } from 'infra/services/api';
import { arrays, define_settings} from 'app/views/settings/contexts/SettingsContext';
import { store } from 'core/redux/store';
import { useDispatch } from 'react-redux';
import { quoteGetSettings } from 'infra/services/api';


const initialAuthState = {
    user: null,
    isInitialised: false,
    isAuthenticated: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_AUTH_STATE_CHANGED': {
            const { isAuthenticated, user } = action.payload;
            return { ...state, isAuthenticated, isInitialised: true, user };
        }

        default: {
            return state;
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'LOGIN',
    createUserWithEmail: (email, password) => Promise.resolve(),
    signInWithEmail: (email, password) => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch2 = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const { login, logout: _logout } = authProvider;
    const _isAuthenticated = localStorage.getItem('is-authenticated');

    const signInWithEmail = async (email, password) => {
        await login(email, password);
    };

    const logout = async () => {
        await _logout();
        navigate('/session?page=login');
    };

    const formatData = (settings_data) => {
        const { provider_settings } = settings_data;
        if (Array.isArray(provider_settings)) {
            const json_data = {};

            provider_settings.forEach((element) => {
                let value = '';
                if (arrays.indexOf(element.setting) !== -1) {
                    value = JSON.parse(element.value);
                } else {
                    value = element.value;
                }

                json_data[element.setting] = value;
            });
            dispatch2(setSettings({...define_settings, ...json_data }));
        }
    };

    useEffect(() => {
        
        const _checkAuth = async () => {
            if (_isAuthenticated) {
                // refetch the provider settings
                const response = await getSettings(localStorage.getItem('account_id'));
                if (response) {
                    formatData(response);
                }

                dispatch({
                    type: 'LOGIN_AUTH_STATE_CHANGED',
                    payload: {
                        isAuthenticated: true,
                        user: {
                            id: localStorage.getItem('id'),
                            email: localStorage.getItem('email'),
                            avatar: localStorage.getItem('avatar'),
                            name: localStorage.getItem('name'),
                            username: localStorage.getItem('username'),
                        },
                    },
                });
            } else {
                // refetch the provider settings
                const response = await quoteGetSettings(0);
                if (response) {
                    formatData(response);
                }

                dispatch({
                    type: 'LOGIN_AUTH_STATE_CHANGED',
                    payload: { isAuthenticated: false, user: null },
                });
            }
        };
        _checkAuth();
    }, [dispatch, _isAuthenticated]);

    // if (!state.isInitialised) {
    //     return <MatxLoading />;
    // }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                logout,
                method: 'LOGIN',
                signInWithEmail,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;