import "index.css";
import { StyledEngineProvider } from '@mui/styled-engine';
import { SnackbarProvider } from 'notistack';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './app/App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StyledEngineProvider injectFirst>
        <SnackbarProvider
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            <BrowserRouter basename="/">
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </StyledEngineProvider>
);
