import { API_URL } from 'config.js';
import { get, upload, put, post, patch } from './helpers';

export const getSalesAgents = async () => {
    const response = await get(`${API_URL}/sales-agents`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getEnterpriseTypes = async () => {
    const response = await get(`${API_URL}/enterprise-types`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAccounts = async () => {
    const response = await get(`${API_URL}/accounts`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAccount = async (url) => {
    const response = await get(`${API_URL + url}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAccountAdressBook = async (url) => {
    const response = await get(`${API_URL + url}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAccountNotes = async (id) => {
    const response = await get(`${API_URL}/accounts/notes/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getChoicesOptin = async (id) => {
    const response = await get(
        `${API_URL}/accounts/active-rate-groups?account_id=${id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();

    return data;
};

export const addAccountNotes = async (payload) => {
    const response = await upload(`${API_URL}/accounts/notes`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const updateGroup = async (id, payload) => {
    const response = await put(`${API_URL}/rates/groups/${id}`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const getStatements = async (account_id, start_date, end_date) => {
    const response = await get(
        `${API_URL}/billing/statements?account_id=${account_id}&start_date=${start_date}&end_date=${end_date}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const invoice_run = async (payload) => {
    const response = await post(`${API_URL}/accounts/invoice-run`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const get_invoice = async (id) => {
    const response = await get(`${API_URL}/billing/invoices/${id}`);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const get_credit = async (id) => {
    const response = await get(`${API_URL}/billing/credit-notes/${id}`);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const allocate_credit = async (payload) => {
    const response = await post(`${API_URL}/accounts/allocate`, payload);
    return {
        status: response?.success,
        ...(await response.json()),
    };
};

export const save_address_book = async (payload) => {
    const response = await post(`${API_URL}/accounts/address-books`, payload);
    return {
        status: response?.success,
        ...(await response.json()),
    };
};

export const resend_invitation = async (payload) => {
    const response = await post(
        `${API_URL}/users/resend-confirmation`,
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const account_bulk_edit = async (payload) => {
    const response = await patch(`${API_URL}/accounts/bulk`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const account_download_csv = async (payload) => {
    const response = await post(`${API_URL}/accounts/download_csv`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};

export const getTransactionsExport = async (params) => {
    const response = await get(`${API_URL}/billing/transactions?${params}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const recalculate_credit_allocations = async (payload) => {
    const response = await post(
        `${API_URL}/accounts/recalculate-balance`,
        payload
    );
    return {
        status: response?.status,
        ...(await response.json()),
    };
};
