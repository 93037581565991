import React from 'react';
import Save from '@mui/icons-material/Save';
import { Button as MUIButton } from '@mui/material';

const Button = ({ variant, label, ...props }) => {
    const IconComp = () => {
        if (props.startIcon) {
            if (props.icon) {
                return props.icon;
            } else {
                return <Save />;
            }
        }
    };

    return (
        <MUIButton {...props} variant={variant} startIcon={<IconComp />}>
            {label}
        </MUIButton>
    );
};

export default Button;

Button.defaultProps = {
    type: 'button',
    variant: 'contained',
    label: 'Save',
    color: 'primary',
    startIcon: true, // false
};
