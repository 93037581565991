export const navigations = [
    {
        name: 'Analytics',
        resource_name: 'dashboard',
        path: '/dashboard',
        icon: 'trending_up',
    },
    // {
    //     name: 'Ops dashboard',
    //     resource_name: 'ops-dashboard',
    //     path: '/ops-dashboard',
    //     icon: 'dashboard',
    // },
    {
        label: 'Pages',
        type: 'label',
    },
    {
        name: 'Shipments',
        resource_name: 'shipments',
        path: '/shipments',
        icon: 'local_shipping',
    },
    // {
    //     name: 'Bulk Collections',
    //     resource_name: 'collection-requests',
    //     icon: 'list',
    //     children: [
    //         {
    //             name: 'Bulk Collections',
    //             resource_name: 'collection-requests/bulk',
    //             path: '/collection-requests/bulk',
    //             iconText: 'BC',
    //         },
    //         {
    //             name: 'Recurring bulk collections',
    //             resource_name: 'collection-requests/recurring',
    //             path: '/collection-requests/recurring',
    //             iconText: 'RBC',
    //         },
    //     ],
    // },
    {
        name: 'Maps',
        resource_name: 'maps',
        path: '/maps',
        icon: 'map',
    },
    // {
    //     name: 'Hub scanner',
    //     resource_name: 'hub-scanner',
    //     path: '/hub-scanner',
    //     icon: 'space_bar',
    // },
    // {
    //     name: 'Interhub manifests',
    //     resource_name: 'interhub-transfers',
    //     path: '/interhub-transfers',
    //     icon: 'transfer_within_a_station',
    // },
    // {
    //     name: 'Interhub manifests',
    //     resource_name: 'interhub-transfers',
    //     icon: 'list',
    //     children: [
    //         {
    //             name: 'Interhub manifests',
    //             resource_name: 'interhub-transfers',
    //             path: '/interhub-transfers',
    //             iconText: 'IT',
    //         },
    //         {
    //             name: 'Hub scanner',
    //             resource_name: 'hub-scanner',
    //             path: '/hub-scanner',
    //             iconText: 'HS',
    //         },
    //     ],
    // },
    // {
    //     name: 'Tools',
    //     resource_name: 'tools',
    //     path: '/tools',
    //     icon: 'ac_unit',
    // },
    {
        name: 'Smart Terminals',
        resource_name: 'smart-terminals',
        icon: 'view_column',
        children: [
            {
                name: 'Lockers (View)',
                resource_name: 'lockers',
                path: '/lockers',
                iconText: 'BC',
            },
            {
                name: 'Admin',
                resource_name: 'admin',
                icon: 'settings',
                children: [
                    {
                        name: 'Lockers',
                        resource_name: 'admin/lockers',
                        path: '/admin/lockers',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Locker Access',
                        resource_name: 'admin/locker-access',
                        path: '/admin/locker-access',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Doors',
                        resource_name: 'admin/doors',
                        path: '/admin/doors',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Networks',
                        resource_name: 'admin/networks',
                        path: '/admin/networks',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Promo Codes',
                        resource_name: 'admin/promo-codes',
                        path: '/admin/promo-codes',
                        iconText: 'RBC',
                    },
                ],
            },
        ],
    },
    // {
    //     name: 'Billing',
    //     resource_name: 'billing',
    //     icon: 'view_stream',
    //     children: [
    //         {
    //             name: 'Imported payments',
    //             resource_name: 'billing/payments',
    //             path: '/billing/payments',
    //             iconText: 'BC',
    //         },
    //         {
    //             name: 'Imported credit allocations',
    //             resource_name: 'billing/credit-allocations',
    //             path: '/billing/credit-allocations',
    //             iconText: 'RBC',
    //         },
    //         {
    //             name: 'All invoices',
    //             resource_name: 'billing/invoices',
    //             path: '/billing/invoices',
    //             iconText: 'RBC',
    //         },
    //         {
    //             name: 'All credit notes',
    //             resource_name: 'billing/credit-notes',
    //             path: '/billing/credit-notes',
    //             iconText: 'RBC',
    //         },
    //     ],
    // },
    // {
    //     name: 'Address books',
    //     resource_name: 'address-books',
    //     path: '/address-books',
    //     icon: 'library_books',
    // },
    {
        name: 'Accounts',
        resource_name: 'accounts',
        path: '/accounts',
        icon: 'star',
    },
    {
        name: 'Account',
        resource_name: 'account',
        path: '/account/' + localStorage.getItem('account_id'),
        icon: 'star',
    },
    {
        name: 'Users',
        resource_name: 'users',
        path: '/users',
        icon: 'people',
    },
    {
        name: 'Rates and estimates',
        resource_name: 'rates-and-estimates',
        path: '/rates-and-estimates',
        icon: 'attach_money',
    },
    {
        name: 'Reports',
        resource_name: 'reports',
        path: '/reports',
        icon: 'view_week',
    },
    {
        name: 'Settings',
        resource_name: 'settings',
        path: '/settings',
        icon: 'settings',
    },
    // {
    //     name: 'My inbox',
    //     resource_name: 'my-inbox',
    //     path: '/my-inbox',
    //     icon: 'messages',
    // },
    {
        name: 'My profile',
        resource_name: 'my-profile',
        path: '/my-profile',
        icon: 'verified_user',
    },
    {
        name: 'Track a parcel',
        resource_name: 'track',
        path: '/track',
        icon: 'gps_fixed',
    },
    {
        name: 'Amazon Shipments',
        resource_name: 'amazon-shipments',
        icon: 'view_column',
        children: [
            {
                name: 'Tools',
                resource_name: 'amazon-shipments/tools',
                path: '/amazon-shipments/tools',
                iconText: 'RBC',
            },
            {
                name: 'Shipments',
                resource_name: 'amazon-shipments/lists',
                path: '/amazon-shipments/lists',
                iconText: 'RBC',
            },
            {
                name: 'Logs',
                resource_name: 'admin',
                icon: 'settings',
                children: [
                    {
                        name: 'Events Logs',
                        resource_name: 'amazon-shipments/event-shipments/',
                        path: 'amazon-shipments/event-shipments',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Shipments Logs',
                        resource_name: 'amazon-shipments/shipments',
                        path: '/amazon-shipments/shipments',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Webhook Logs',
                        resource_name: 'amazon-shipments/webhook-logs',
                        path: '/amazon-shipments/webhook-logs',
                        iconText: 'RBC',
                    },
                    {
                        name: 'Inbound Logs',
                        resource_name: 'amazon-shipments/inbound-logs',
                        path: '/amazon-shipments/inbound-logs',
                        iconText: 'RBC',
                    },
                ],
            },
        ],
    },
    {
        name: 'Sent SMS',
        resource_name: 'sent-sms',
        path: '/sent-sms',
        icon: 'sms',
    },
    {
        name: 'Inbound xml',
        resource_name: 'inbound-xml',
        path: '/inbound-xml',
        icon: 'file',
    },
];

export const getfilteredNavigations = (navList = [], role) => {
    return navList.reduce((array, nav) => {
        if (nav.auth && nav.auth.includes(role)) {
            array.push(nav);
        } else {
            if (nav.children) {
                nav.children = getfilteredNavigations(nav.children, role);
                array.push(nav);
            }

            array.push(nav);
        }
        return array;
    }, []);
};
