export const SET_IS_LOGIN = 'SET_IS_LOGIN';
export const SET_MAPS_DRIVERS = 'SET_MAPS_DRIVERS';
export const SET_MAPS_BRANCHES = 'SET_MAPS_BRANCHES';
export const SET_MAPS_RATES = 'SET_MAPS_RATES';
export const SET_MAPS_SURCHARGES = 'SET_MAPS_SURCHARGES';
export const SET_MAPS_SERVCES_DAYS = 'SET_MAPS_SERVCES_DAYS';
export const SET_MAPS_DRAFT = 'SET_MAPS_DRAFT';

export const SET_CURRENT_MAP_SETTINGS = 'SET_CURRENT_MAP_SETTINGS';
export const SET_USER_DRIVERS = 'SET_USER_DRIVERS';
export const SET_ZONE_TAGS = 'SET_ZONE_TAGS';

export const SET_GENERAL_DATA = 'SET_GENERAL_DATA';
export const SET_SALES_AGENTS = 'SET_SALES_AGENTS';
export const SET_ENTERPRISE_TYPES = 'SET_ENTERPRISE_TYPES';
export const SET_RATE_GROUPS = 'SET_RATE_GROUPS';

export const SET_SERVICE_DAYS_TAGS = 'SET_SERVICE_DAYS_TAGS';
export const SET_SERVICE_DAYS_ZONES = 'SET_SERVICE_DAYS_ZONES';

export const SET_SURCHARGES_ZONES = 'SET_SURCHARGES_ZONES';
export const SET_SURCHARGES_TAGS = 'SET_SURCHARGES_TAGS';

export const SET_RATES_ZONES = 'SET_RATES_ZONES';
export const SET_RATES_TAGS = 'SET_RATES_TAGS';

export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_LOCKERS_DATA = 'SET_LOCKERS_DATA';
