import * as React from 'react';
import { NumberInput as RANumberInput } from 'react-admin';

const NumberInput = ({ ...props }) => {
    return <RANumberInput {...props} />;
};

export default NumberInput;

NumberInput.defaultProps = {
    isRequired: false,
    fullWidth: true,
    variant: 'outlined', // outlined ||  filled ||  standard
    size: 'normal', // normal || small
    margin: 'none', // dense
    color: 'secondary', // warning || secondary || warning
};
