const Layout2Settings = {
    mode: 'contained', // boxed | contained | full
    topbar: {
        show: true,
        fixed: true,
        theme: 'slateDark1',
    },
    navbar: {
        show: true,
        theme: 'slateDark2',
    },
    leftSidebar: {
        // Only for mobile devices
        show: true,
        mode: 'close', // full | close | compact | mobile
        theme: 'slateDark1',
    },
};

export default Layout2Settings;
