import React from 'react';

import { Dialog } from 'app/components';
import GoogleMapComp from './GoogleMapComp';

const DialogMap = ({
    maxWidth,
    open,
    onClose,
    showActions,
    ...rests
}) => {

    return (
        <Dialog
                maxWidth={maxWidth}
                open={open}
                onClose={onClose}
                title={'Map'}
                showActions={showActions}
            >
         {open ? <GoogleMapComp/>: null}
        </Dialog>
    );
};

export default DialogMap;
